<template>
  <main>
    <div class="container">
      <div class="col-sm-8 mx-auto py-5">
        <div class="bg-white p-4 rounded-lg shadow-md">
          <div class="row">
            <div class="col-sm-12">
              <div class="text-center mb-4">
                <h4 class="text-primary">Enregistrement d'une offre</h4>
              </div>
            </div>
          </div>
          <p-progress-bar v-if="busy" style="height: 0.33em" mode="indeterminate" />
          <div class="row">
            <div class="col-sm-12">
              <OffreEditorForm ref="offreForm" v-model="offre" />

              <div class="row">
                <div class="col">
                  <div class="d-grid gap-2 my-4">
                    <button
                      @click.prevent="submitForm"
                      type="submit"
                      class="btn btn-primary btn-lg"
                      :disabled="busy"
                    >
                      Soumettre
                    </button>
                  </div>
                </div>
              </div>
              <!-- <h6 class="text-muted text-center">J'ai un compte candidat <a href="connexion.html" class="text-primary">connectez-vous</a></h6> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapActions, mapMutations } from "vuex";
import OffreEditorForm from "../../../components/espace/entreprise/offre/steps/OffreEditorForm.vue";
// import DisponibiliteActuelle from '@/components/register/offre/steps/DisponibiliteActuelle.vue'
import { authMixin } from "../../../mixins/auth-mixin";

export default {
  components: {
    OffreEditorForm,
  },
  mixins: [authMixin],
  data() {
    return {
      currentStep: 0,
      offre: {},
      error: false,
      busy: false,
    };
  },
  created() {
    if (this.$userHasRole("ENTREPRISE")) {
      this.offre = {
        ...this.offre,
        typeOffre: "EXTERNE",
      };
    }
  },
  watch: {
    "offre.phone": {
      handler() {
        this.error = false;
      },
    },
  },
  computed: {},
  methods: {
    ...mapActions({
      createOrUpdateOffre: "offre/createOrUpdateOffre",
    }),
    async submitForm() {
      if (await this.$refs.offreForm.isCompleted()) {
        this.busy = true;

        this.offre = {
          ...this.offre,
        };
        this.createOrUpdateOffre(this.offre)
          .then(async (de) => {
            this.$toast.success("Opération réussie!", {
              position: "bottom-right",
              duration: 5000,
            });
            console.log(de);
            this.$router.push({ name: "espace.entreprise.offre.list" });
            this.busy = false;
          })
          .catch(() => {
            this.error = true;
            this.$toast.error(`Erreur d'ajout d'offre.`, {
              position: "bottom-right",
              duration: 35000,
            });
            this.busy = false;
          });
      }
    },
  },
};
</script>

<style>
.vue-form-wizard .wizard-progress-with-circle,
.vue-form-wizard .wizard-header,
.vue-form-wizard .wizard-nav.wizard-nav-pills {
  display: none;
}

.wizard-progress-with-circle {
  background: rgba(0, 0, 0, 0.2);
}

/* .vue-form-wizard .wizard-icon-circle .wizard-icon-container{
  }
  .vue-form-wizard .wizard-icon-circle{
  } */

/* .main-wizard {
    min-height: 60vh;
  } */
</style>
